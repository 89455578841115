@import url('https://fonts.googleapis.com/css2?family=Rubik+Scribble&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&family=Rubik+Scribble&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Honk&family=Rubik+Scribble&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Honk&family=Oswald:wght@200..700&family=Rubik+Scribble&family=Tilt+Neon&display=swap');

body{
    font-family: "Tilt Neon", sans-serif;
    background-image: url('./assets/bg.svg');
    background-size: cover; /* Ajusta el tamaño del fondo */
    /* Otros estilos según tus necesidades */  
}
h3{
    font-family: "Rubik Scribble", system-ui;
}
.rubik-scribble-regular {
    font-family: "Oswald", sans-serif;
  }
  
nav{
    z-index: 75;
}

.gradientBG{
    background: linear-gradient(99deg, #5961F9 0%, #B9FB93 100%);
}
.btnPrimary{
    @apply py-2 px-8 bg-secondary font-semibold text-white rounded hover:bg-primary transition-all duration-300;
}
.BGgd{
    background: linear-gradient(99deg, #5BEDDE 0%, #80F7BB 100%);
}

.deg{
mask-image: linear-gradient(
    black 80%,
    transparent,
);
}
.background-radial-gradient {
    background-color: hsl(218, 41%, 15%);
    background-image: radial-gradient(650px circle at 0% 0%,
        hsl(218, 41%, 35%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(218, 41%, 45%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%);}